import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_JP } from "./jp/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATION_CN } from "./cn/translations";
import { TRANSLATION_TW } from "./tw/translations";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lang: "en",
    fallbackLng: "en",
    resources: {
      jp: {
        translation: TRANSLATIONS_JP,
      },
      en: {
        translation: TRANSLATIONS_EN,
      },
      cn: {
        translation: TRANSLATION_CN,
      },
      tw: {
        translation: TRANSLATION_TW,
      },
    },
  });

export { i18n };
