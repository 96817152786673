/* eslint-disable */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import icons from "../../assets/images/objectSet.svg";
import iconLang from "../../assets/images/icon_lang.svg";

import "./style.scss";

const Navigation = ({ lang }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openLang, setOpenLang] = useState(false);
  return (
    <header>
      <div className="header flex justify-between bg-twbg">
        <h1 className="logo my-5 mx-10">
          <a href="https://www.kagamino.holiday/">
            <img
              src="https://www.kagamino.holiday/themes/main/images/logo.svg"
              alt="健康のまち岡山県鏡野町 観光＆移住総合サイト かがみの旅とくらし"
              width="150"
              height="64"
            />
          </a>
        </h1>
        <div className="inner flex justify-end">
          <ul className="gnav hidden justify-end mt-5 2xl:flex">
            <li className="js-link_match_location">
              <a href="https://www.kagamino.holiday/special/">
                <img
                  src="https://www.kagamino.holiday/themes/main/images/icon_special.svg"
                  alt=""
                  width="32"
                  height="32"
                  className="mb-[5px] mx-auto"
                />
                季節の特集
              </a>
            </li>
            <li className="js-link_match_location">
              <a href="https://www.kagamino.holiday/spot/">
                <img
                  src="https://www.kagamino.holiday/themes/main/images/icon_spot.svg"
                  alt=""
                  width="32"
                  height="32"
                  className="mb-[5px] mx-auto"
                />
                観光スポット
              </a>
            </li>
            <li className="js-link_match_location">
              <a href="https://www.kagamino.holiday/gourmet/">
                <img
                  src="https://www.kagamino.holiday/themes/main/images/icon_gourmet.svg"
                  alt=""
                  width="32"
                  height="32"
                  className="mb-[5px] mx-auto"
                />
                食べる
              </a>
            </li>
            <li className="js-link_match_location">
              <a href="https://www.kagamino.holiday/hotel/">
                <img
                  src="https://www.kagamino.holiday/themes/main/images/icon_stay.svg"
                  alt=""
                  width="32"
                  height="32"
                  className="mb-[5px] mx-auto"
                />
                宿泊
              </a>
            </li>
            <li className="js-link_match_location">
              <a href="https://www.kagamino.holiday/event/">
                <img
                  src="https://www.kagamino.holiday/themes/main/images/icon_event.svg"
                  alt=""
                  width="32"
                  height="32"
                  className="mb-[5px] mx-auto"
                />
                イベント
              </a>
            </li>
            <li className="js-link_match_location">
              <button
                id="js-dropnavi"
                type="button"
                className="dropnavi"
                aria-controls="dmenu"
                aria-expanded="false"
                aria-label="メニューを開く"
              >
                <img
                  src="https://www.kagamino.holiday/themes/main/images/icon_iju.svg"
                  alt=""
                  width="32"
                  height="32"
                  className="mb-[5px] mx-auto"
                />
                移住定住
              </button>
              <div className="dmenu hidden" id="dmenu">
                <ul>
                  <li>
                    <a href="https://www.kagamino.holiday/voice/">
                      先輩の移住体験
                    </a>
                  </li>
                  <li>
                    <a href="https://www.kagamino.holiday/now/">
                      田舎暮らしNOW
                    </a>
                  </li>
                  <li>
                    <a href="https://www.kagamino.holiday/tour/">
                      移住体験ツアー／相談会
                    </a>
                  </li>
                  <li>
                    <a href="https://www.kagamino.holiday/support/">
                      行政支援一覧
                    </a>
                  </li>
                  <li>
                    <a href="https://www.kagamino.holiday/faq-passage/">
                      移住定住Q&amp;A
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <ul className="cv hidden 2xl:flex ml-5">
            <li>
              <a href="https://kagamino.tours/">
                <img
                  src="https://www.kagamino.holiday/themes/main/images/icon_contents.svg"
                  alt=""
                  width="24"
                  height="24"
                />
                体験プラン
                <br />
                <span className="small">を予約する</span>
              </a>
            </li>
            <li>
              <a href="https://www.kagamino.holiday/kenkou-club/">
                <span className="x-small">かがみの</span>
                <br />
                里山健康
                <br />
                クラブ
                <br />
                <span className="small">入会受付中</span>
              </a>
            </li>
          </ul>
          <button
            id="js-hamburger"
            type="button"
            className={`hamburger ${open ? "-active" : ""} z-40`}
            aria-controls="gmenu"
            aria-expanded="false"
            aria-label="メニューを開閉する"
            onClick={() => setOpen(!open)}
          >
            <span className="line"></span>
            <span className="text"></span>
          </button>
        </div>
        <div
          className={`gmenu transition duration-700 ${
            open ? "translate-x-0 visible" : "translate-x-full invisible"
          }`}
          id="gmenu"
          aria-hidden="false"
        >
          <div className={`inner ${open ? "active" : ""}`}>
            <div className="menu">
              <p className="logo sp">
                <a href="https://www.kagamino.holiday/">
                  <img
                    src="https://www.kagamino.holiday/themes/main/images/logo_w.svg"
                    alt="健康のまち岡山県鏡野町 観光＆移住総合サイト かがみの旅とくらし"
                    width="150"
                    height="64"
                  />
                </a>
              </p>
              <nav className="flex">
                <ul>
                  <li>
                    <a href="https://www.kagamino.holiday/charm/">
                      鏡野町の魅力
                    </a>
                  </li>
                  <li>
                    <a href="https://www.kagamino.holiday/special/">特集</a>
                  </li>
                  <li>
                    <a href="https://www.kagamino.holiday/spot/">
                      観光スポット
                    </a>
                  </li>
                  <li>
                    <a href="https://www.kagamino.holiday/gourmet/">食べる</a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href="https://www.kagamino.holiday/hotel/">宿泊</a>
                  </li>
                  <li>
                    <a href="https://www.kagamino.holiday/event/">イベント</a>
                  </li>
                  <li>
                    <a href="https://www.kagamino.holiday/access/">
                      鏡野町へのアクセス
                    </a>
                  </li>
                  <li>
                    <a href="https://www.kagamino.holiday/faq-sightseeing/">
                      よくある質問
                    </a>
                  </li>
                </ul>
                <dl>
                  <dt>移住定住</dt>
                  <dd>
                    <ul>
                      <li>
                        <a href="https://www.kagamino.holiday/voice/">
                          先輩の移住体験
                        </a>
                      </li>
                      <li>
                        <a href="https://www.kagamino.holiday/now/">
                          田舎暮らしNOW
                        </a>
                      </li>
                      <li>
                        <a href="https://www.kagamino.holiday/tour/">
                          移住体験ツアー／相談会
                        </a>
                      </li>
                      <li>
                        <a href="https://www.kagamino.holiday/support/">
                          行政支援一覧
                        </a>
                      </li>
                      <li>
                        <a href="https://www.kagamino.holiday/faq-passage/">
                          移住定住Q&amp;A
                        </a>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </nav>
              <ul className="btn">
                <li>
                  <a href="https://kagamino.tours/">
                    <div>
                      <p>体験プランを予約する</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        className={`icon`}
                      >
                        <use xlinkHref={`${icons}#icon-arrow-circle`} />
                      </svg>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="https://www.kagamino.holiday/kenkou-club/">
                    <div>
                      <p>
                        <span className="text-sm">かがみの里山健康クラブ</span>
                        無料入会受付中！
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        className={`icon`}
                      >
                        <use xlinkHref={`${icons}#icon-arrow-circle`} />
                      </svg>
                    </div>
                  </a>
                </li>
              </ul>
              <div className="flex flex-col lg:flex-row justify-center lg:justify-end items-center mb-8">
                <ul className="utility flex !mb-8 lg:mb-0">
                  <li>
                    <a href="https://www.kagamino.holiday/contact/">
                      お問い合わせ
                    </a>
                  </li>
                  <li>
                    <a href="https://www.kagamino.holiday/about/">
                      プライバシーポリシー
                    </a>
                  </li>
                  <li>
                    <a href="https://www.kagamino.holiday/sitemap/">
                      サイトマップ
                    </a>
                  </li>
                </ul>
                <p className="bn">
                  <a href="http://www.town.kagamino.lg.jp/" target="_blank">
                    <img
                      src="https://www.kagamino.holiday/themes/main/images/bn_townkagamino.png"
                      alt="鏡野町"
                    />
                  </a>
                </p>
              </div>
              <div className="flex flex-col lg:flex-row justify-end items-center">
                <ul className="sns order-2 lg:order-1">
                  <li className="sp">Official SNS</li>
                  <li>
                    <a
                      href="https://www.facebook.com/mizurin.fairy/"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        className={`icon`}
                      >
                        <use xlinkHref={`${icons}#icon-facebook`} />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/mizurin_fairy" target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        className={`icon`}
                      >
                        <use xlinkHref={`${icons}#icon-x`} />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/kagaminogram/"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        className={`icon`}
                      >
                        <use xlinkHref={`${icons}#icon-instagram`} />
                      </svg>
                    </a>
                  </li>
                </ul>
                <dl
                  className={`lang relative order-1 lg:order-2 ${
                    openLang
                      ? "!rounded-br-none !rounded-bl-none border-b border-twprimary border-dashed"
                      : ""
                  }`}
                >
                  <dt onClick={() => setOpenLang(!openLang)}>Language</dt>
                  <div className="absolute right-4 top-1/2 -translate-y-1/2 top-[50px]">
                    <img src={iconLang} alt="" />
                  </div>
                  <div
                    className={`${
                      openLang ? "max-h-40 visible" : "max-h-0 invisible"
                    } transition absolute bg-white w-full rounded-br-2xl rounded-bl-2xl `}
                  >
                    <dd>
                      <a
                        className="!text-twprimary"
                        href="https://www.kagamino.holiday/en/"
                      >
                        English
                      </a>
                    </dd>
                    <dd>
                      <a
                        className="!text-twprimary"
                        href="https://www.kagamino.holiday/tw/"
                      >
                        繁体中文
                      </a>
                    </dd>
                    <dd>
                      <a
                        className="!text-twprimary"
                        href="https://www.kagamino.holiday/cn/"
                      >
                        簡体中文
                      </a>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navigation;
