import React from "react";
import PropTypes from "prop-types";
import icons from "../../assets/images/objectSet.svg";

import "./style.scss";

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="inner">
          <p className="logo text-center lg:mb-20 mb-8 min-w-[170px]">
            <a href="https://www.kagamino.holiday/">
              <img
                src="https://www.kagamino.holiday/themes/main/images/logo.svg"
                width="313"
                height="133"
                alt="健康のまち岡山県鏡野町 観光＆移住総合サイト かがみの旅とくらし"
                className="mx-auto"
              />
            </a>
          </p>

          <div className="flex flex-wrap lg:flex-nowrap mb-4 justify-between">
            <ul className="btn order-1 lg:order-2">
              <li>
                <a
                  href="https://book.txj.co.jp/v4/Pages/search.aspx?exl_dn=kagaminokankou&amp;exl_lng=ja-JP&amp;exl_cur=JPY&amp;exl_mod=act"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>
                    <p>体験プランを予約する</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      className={`icon`}
                    >
                      <use xlinkHref={`${icons}#icon-arrow-circle-white`} />
                    </svg>
                  </div>
                </a>
              </li>
              <li>
                <a href="https://www.kagamino.holiday/kenkou-club/">
                  <div>
                    <p>
                      <span>かがみの里山健康クラブ</span>
                      <p>無料入会受付中！</p>
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      className={`icon`}
                    >
                      <use xlinkHref={`${icons}#icon-arrow-circle-white`} />
                    </svg>
                  </div>
                </a>
              </li>
            </ul>
            <nav className="flex font-bold flex-wrap lg:flex-nowrap order-2 lg:order-1 justify-between">
              <ul>
                <li>
                  <a href="https://www.kagamino.holiday/charm/">鏡野町の魅力</a>
                </li>
                <li>
                  <a href="https://www.kagamino.holiday/special/">特集</a>
                </li>
                <li>
                  <a href="https://www.kagamino.holiday/spot/">観光スポット</a>
                </li>
                <li>
                  <a href="https://www.kagamino.holiday/gourmet/">食べる</a>
                </li>
              </ul>
              <ul>
                <li>
                  <a href="https://www.kagamino.holiday/hotel/">宿泊</a>
                </li>
                <li>
                  <a href="https://www.kagamino.holiday/event/">イベント</a>
                </li>
                <li>
                  <a href="https://www.kagamino.holiday/access/">
                    鏡野町へのアクセス
                  </a>
                </li>
                <li>
                  <a href="https://www.kagamino.holiday/faq-sightseeing/">
                    よくある質問
                  </a>
                </li>
              </ul>
              <dl>
                <dt className="!font-bold">移住定住</dt>
                <dd>
                  <ul>
                    <li>
                      <a href="https://www.kagamino.holiday/voice/">
                        先輩の移住体験
                      </a>
                    </li>
                    <li>
                      <a href="https://www.kagamino.holiday/now/">
                        田舎暮らしNOW
                      </a>
                    </li>
                    <li>
                      <a href="https://www.kagamino.holiday/tour/">
                        移住体験ツアー／相談会
                      </a>
                    </li>
                    <li>
                      <a href="https://www.kagamino.holiday/support/">
                        行政支援一覧
                      </a>
                    </li>
                    <li>
                      <a href="https://www.kagamino.holiday/faq-passage/">
                        移住定住Q&amp;A
                      </a>
                    </li>
                  </ul>
                </dd>
              </dl>
            </nav>
          </div>

          <div className="flex  flex-wrap lg:flex-nowrap justify-between mb-6">
            <ul className="utility flex lg:order-2 text-[10px] lg:text-sm !mb-10 lg:mb-0">
              <li>
                <a href="https://www.kagamino.holiday/contact/">お問い合わせ</a>
              </li>
              <li>
                <a href="https://www.kagamino.holiday/about/">
                  プライバシーポリシー
                </a>
              </li>
              <li>
                <a href="https://www.kagamino.holiday/sitemap/">サイトマップ</a>
              </li>
            </ul>
            <p className="name lg:order-1 text-center lg:text-left">
              一般社団法人 鏡野観光局
              <br />
              <span className="small">
                鏡野町ふるさと村特別村民事務局・かがみの里山健康クラブ事務局
              </span>
            </p>
          </div>

          <div className="flex items-center mb-10 flex-col lg:flex-row">
            <p className="bn mb-8 lg:mb-0 lg:mr-8 hover:opacity-70">
              <a
                href="http://www.town.kagamino.lg.jp/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://www.kagamino.holiday/themes/main/images/bn_townkagamino.png"
                  alt="鏡野町"
                />
              </a>
            </p>

            <dl className="sns flex flex-col lg:flex-row">
              <dt className="text-center lg:text-left font-serif mb-1 lg:mb-0">
                Official SNS
              </dt>
              <div className="flex items-center text-center">
                <dd>
                  <a
                    href="https://www.facebook.com/mizurin.fairy/"
                    target="_blank"
                    className="hover:opacity-70"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      className={`icon`}
                    >
                      <use xlinkHref={`${icons}#icon-facebook`} />
                    </svg>
                  </a>
                </dd>
                <dd>
                  <a
                    href="https://twitter.com/mizurin_fairy"
                    className="hover:opacity-70"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      className={`icon`}
                    >
                      <use xlinkHref={`${icons}#icon-x`} />
                    </svg>
                  </a>
                </dd>
                <dd>
                  <a
                    href="https://www.instagram.com/kagaminogram/"
                    target="_blank"
                    className="hover:opacity-70"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      className={`icon`}
                    >
                      <use xlinkHref={`${icons}#icon-instagram`} />
                    </svg>
                  </a>
                </dd>
              </div>
            </dl>
          </div>
          <p className="copyright text-xs text-center font-bold font-serif">
            © Kagamino Town
          </p>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  language: PropTypes.string,
  changeLanguage: PropTypes.func,
};

export default Footer;
