import React, { useEffect, useState, useRef, useCallback } from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useCart } from "react-use-cart";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/ja";
import { toast } from "react-toastify";
import Slider from "react-slick";

import DefaultImg from "../../../../assets/images/no_image.png";
import { formatMoney } from "../../../../helpers/formatters";

import "../../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";

const propTypes = {
  bookingQuotes: PropTypes.array,
  changeQuantity: PropTypes.func,
  onRequest: PropTypes.string,
  service: PropTypes.object,
  quotesInfo: PropTypes.object,
  error: PropTypes.bool,
  language: PropTypes.string,
  totalPrice: PropTypes.array,
  changePax: PropTypes.func,
};

const ProductItems = ({
  bookingQuotes,
  changeQuantity,
  onRequest,
  service,
  quotesInfo,
  error,
  language,
  totalPrice,
  changePax,
}) => {
  const { addItem, items } = useCart();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [descMore, setDescMore] = useState([]);
  const [extras] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState();
  const [errorItems, setErrorItems] = useState(false);
  const [openModal, setOpenModal] = useState();
  const descRef = useRef([]);
  const [scrollable, setScrollable] = useState([]);
  const [posSlide, setPosSlide] = useState(0);
  const [selectedQuote, setSelectedQuote] = useState();

  useEffect(() => {
    !isEmpty(descRef.current) &&
      descRef.current.length === bookingQuotes.length &&
      descRef.current.map((item) => {
        if (item?.scrollHeight > item?.clientHeight) {
          if (!isEmpty(scrollable)) {
            if (scrollable.indexOf(item) == -1)
              setScrollable((prev) => [...prev, item.id]);
          } else {
            setScrollable((prev) => [...prev, item.id]);
          }
        }
      });
  }, [bookingQuotes]);

  const descNode = useCallback((descNode) => {
    if (descNode?.scrollHeight > descNode?.clientHeight) {
      setScrollable((prev) => [...prev, descNode.id]);
    }
  }, []);

  useEffect(() => {
    if (error) {
      setErrorItems(true);
    }
  }, [error]);

  useEffect(() => {
    language && moment.locale(language === "jp" ? "ja" : language);
  }, [language]);

  const handleClose = () => setShow(false);

  const failed = () => toast.warn(t("warning_dist"));
  const success = () => toast.success(t("item_added"));

  const submitBooking = (booking) => {
    booking.selectedExtras = extras;
    if (onRequest === "true") {
      booking.selectedExtras = extras;
      setSelectedBooking(booking);
      setShow(true);
    } else {
      const checkRequestItems = items.find((item) => item.type === "req");
      if (checkRequestItems) {
        failed();
      } else {
        if (selectedQuote) {
          booking.selectedQuote = selectedQuote;
          booking.id = `${booking.id}-${moment(selectedQuote.Commence).format(
            "LT"
          )}`;
        }
        addItem(booking, parseInt(booking.quantity));
        success();
      }
    }
  };

  const serviceType = () => {
    let serviceType = "None";
    if (service && service.IndustryCategoryGroups) {
      switch (service.IndustryCategoryGroups[0]) {
        case 0:
          serviceType = "Accommodation";
          break;
        case 1:
          serviceType = "Activities";
          break;
        case 2:
          serviceType = "Restaurant";
          break;
        case 3:
          serviceType = "Produce";
          break;
        default:
          return "None";
      }
    }

    return serviceType;
  };

  const goToRequestBook = () => {
    const selectedItems = extras.filter((item) => {
      return item.ParentId === selectedBooking.Id;
    });

    selectedBooking.selectedExtras = selectedItems;
    const address = `${service.PhysicalAddress.Line1}, ${service.PhysicalAddress.City}, ${service.PhysicalAddress.PostCode}, ${service.PhysicalAddress.State}`;
    const request = {
      ProductId: selectedBooking.Id,
      ProductName: selectedBooking.Name,
      ProductExtras: selectedItems,
      ProductCode: selectedBooking.Code,
      Price: selectedQuote
        ? selectedQuote.TotalPrice
        : selectedBooking.Configurations[0].Quotes[0].TotalPrice,
      CurrentCurrency: "JPY",
      Language: language === "jp" || language === "ja-JP" ? "ja" : language,
      IndustryCategoryGroup: serviceType(
        selectedBooking.IndustryCategoryGroups[0]
      ),
      CommencementDate: selectedQuote
        ? selectedQuote.Commence
        : selectedBooking.Configurations[0].Quotes[0].Commence,
      ConcludeDate: selectedQuote
        ? selectedQuote.Conclude
        : selectedBooking.Configurations[0].Quotes[0].Conclude,
      Duration:
        selectedBooking.IndustryCategoryGroups[0] === 0
          ? quotesInfo.duration
          : null,
      Adults: quotesInfo.pax,
      SupplierName: service.Name,
      SupplierAddress: address,
      SupplierEmail: service.PublicEmail,
      SupplierPhone: service.MainPhone.FullPhoneNumberLocalised,
      SupplierWebsite: service.Website,
      SupplierId: service.Id,
      SupplierCode: service.Code,
    };
    navigate(`/request-book?id=${selectedBooking.Id}`, {
      state: { booking: selectedBooking, request: request },
    });
  };

  const seeMore = (id) => {
    if (descMore.length > 0) {
      descMore.map((item) => {
        if (item === id) {
          const data = descMore.filter((item) => item !== id);
          setDescMore(data);
        } else {
          setDescMore((prev) => [...prev, id]);
        }
      });
    } else {
      setDescMore((prev) => [...prev, id]);
    }
  };

  const getPrice = (id, price) => {
    let data = formatMoney(price);
    totalPrice.map((item) => {
      if (item.id === id) data = formatMoney(item.totalPrice);
    });

    return data;
  };

  const duration = (start, end) => {
    const start_time = moment(start);
    const end_time = moment(end);

    // get the difference between the moments
    const diff = end_time.diff(start_time);

    //express as a duration
    const diffDuration = moment.duration(diff);

    return `${
      diffDuration.hours() ? `${diffDuration.hours()} ${t("hours")}` : ""
    } ${
      diffDuration.minutes() ? `${diffDuration.minutes()} ${t("minutes")}` : ""
    }`;
  };

  const settings = {
    dots: true,
    adaptiveHeight: true,
    initialSlide: posSlide,
  };

  const selectQuote = (value) => {
    setSelectedQuote(value);
  };

  const openSliders = (id, pos) => {
    setPosSlide(pos);
    setOpenModal(id);
  };
  return (
    <>
      {!errorItems && !isEmpty(bookingQuotes) ? (
        <div className="items">
          {_.orderBy(
            _.sortBy(bookingQuotes, "Name"),
            "Configurations[0].Quotes[0].TotalPrice",
            "asc"
          ).map((booking, i) => (
            <div key={i} className="productItem align-items-center">
              <div className="info">
                <div className="image">
                  <div
                    className="mainImage"
                    style={{
                      cursor:
                        booking.Images && booking.Images.length > 0
                          ? "pointer"
                          : "default",
                    }}
                  >
                    {booking.Images && booking.Images.length > 0 ? (
                      booking.Images.map((item, i) => {
                        return (
                          <div
                            className="image"
                            key={i}
                            onClick={() => {
                              openSliders(booking.Id, i);
                            }}
                          >
                            <div className="zoomIn">
                              <FontAwesomeIcon icon={faSearchPlus} />
                            </div>
                            <img height="auto" src={item.Url} />
                          </div>
                        );
                      })
                    ) : (
                      <div className="image">
                        <img width={200} src={DefaultImg} />
                      </div>
                    )}
                  </div>
                  <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                    show={booking.Id === openModal}
                    onHide={() => setOpenModal("")}
                    className="imageSliders"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                        {booking.Name}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Slider {...settings}>
                        {booking.Images &&
                          booking.Images.length > 0 &&
                          booking.Images.map((item, i) => {
                            return (
                              <div key={i}>
                                <img className="image" src={item.Url} />
                              </div>
                            );
                          })}
                      </Slider>
                    </Modal.Body>
                  </Modal>
                </div>
                <div>
                  <div className="name">{booking.Name}</div>

                  {booking.IndustryCategoryGroups[0] === 3 ? (
                    <div className="qty d-flex">
                      <label>{t("quantity")}: &nbsp;</label>
                      <input
                        className="py-2 pr-1 border border-gray-300 rounded-lg w-full form-control"
                        onChange={(e) =>
                          changeQuantity(e.target.value, booking.id)
                        }
                        type="number"
                        defaultValue={1}
                        min={1}
                      />
                    </div>
                  ) : (
                    <>
                      <div>
                        {booking.Configurations[0].Quotes && (
                          <div className="mb-3">
                            <div className="qty">
                              {booking.IndustryCategoryGroups[0] !== 0 ? (
                                <>
                                  <div className="d-flex align-items-center flex-wrap">
                                    <label>
                                      {t("start_time", {
                                        time: moment(
                                          selectedQuote
                                            ? selectedQuote.Commence
                                            : booking.Configurations[0]
                                                .Quotes[0].Commence
                                        )
                                          .locale(language)
                                          .format("LLL"),
                                      })}
                                      &nbsp;
                                    </label>
                                    <select
                                      className="timeSelect py-1 px-2 border border-gray-300 rounded-lg"
                                      onChange={(e) =>
                                        selectQuote(
                                          booking.Configurations[0].Quotes[
                                            e.target.value
                                          ]
                                        )
                                      }
                                    >
                                      {booking.Configurations[0].Quotes.map(
                                        (quote, i) => {
                                          return (
                                            <option key={i} value={i}>
                                              {moment(quote.Commence)
                                                .locale(language)
                                                .format("LT")}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </div>
                                  <div>
                                    {t("duration")}:{" "}
                                    {duration(
                                      selectedQuote
                                        ? selectedQuote.Commence
                                        : booking.Configurations[0].Quotes[0]
                                            .Commence,
                                      selectedQuote
                                        ? selectedQuote.Conclude
                                        : booking.Configurations[0].Quotes[0]
                                            .Conclude
                                    )}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div>
                                    {t("check_in_date", {
                                      date: moment(
                                        selectedQuote
                                          ? selectedQuote.Commence
                                          : booking.Configurations[0].Quotes[0]
                                              .Commence
                                      )
                                        .locale(language)
                                        .format("LLL"),
                                    })}
                                  </div>
                                  <div>
                                    {t("check_out_date", {
                                      date: moment(
                                        selectedQuote
                                          ? selectedQuote.Conclude
                                          : booking.Configurations[0].Quotes[0]
                                              .Conclude
                                      )
                                        .locale(language)
                                        .format("LLL"),
                                    })}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="fw-bold d-flex items-center flex-wrap">
                        {booking.Configurations[0].Pax.Adults > 0 ? (
                          <div className="paxInput">
                            <div className="paxText">{t("adults_count")}</div>
                            <input
                              className="py-1 px-3 border border-gray-300 rounded-lg w-[100px] form-control"
                              type="number"
                              min={1}
                              defaultValue={
                                booking.Configurations[0].Pax.Adults
                              }
                              onChange={(e) => changePax(e.target.value, "pax")}
                            />
                          </div>
                        ) : null}
                        {service?.Settings?.PresentChildren ? (
                          <div className="paxInput">
                            <div className="paxText">{t("children_count")}</div>
                            <input
                              className="py-1 px-3 border border-gray-300 rounded-lg w-[100px] form-control"
                              type="number"
                              min={1}
                              defaultValue={
                                booking.Configurations[0].Pax.Children
                              }
                              onChange={(e) =>
                                changePax(e.target.value, "children")
                              }
                            />
                          </div>
                        ) : null}
                        {service?.Settings?.PresentSeniors ? (
                          <div className="paxInput">
                            <div className="paxText">{t("seniors_count")}</div>
                            <input
                              className="py-1 px-3 border border-gray-300 rounded-lg w-[100px] form-control"
                              type="number"
                              min={1}
                              defaultValue={
                                booking.Configurations[0].Pax.Seniors
                              }
                              onChange={(e) =>
                                changePax(e.target.value, "senior")
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                    </>
                  )}
                  {booking.Extras &&
                    booking.IndustryCategoryGroups[0] !== 3 &&
                    booking.Extras.length > 0 && (
                      <div className="extras">
                        <label>{t("extra_options")} &nbsp;</label>
                        <ul className="extraItem" key={i}>
                          {booking.Extras.map((extra, i) => {
                            return (
                              <li key={i}>
                                {extra.Name} ¥{extra.TotalCost}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  {booking.LongDescription && (
                    <>
                      <div
                        className={`desc ${
                          descMore.length > 0 && descMore.includes(booking.Id)
                            ? "active"
                            : ""
                        }`}
                        id={booking.Id}
                        ref={
                          bookingQuotes?.length > 1
                            ? (el) => (descRef.current[i] = el)
                            : descNode
                        }
                        dangerouslySetInnerHTML={{
                          __html: booking.LongDescription,
                        }}
                      ></div>
                      {scrollable.includes(booking.Id) && (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            seeMore(booking.Id);
                          }}
                          className="seeMore"
                        >
                          {descMore.length > 0 && descMore.includes(booking.Id)
                            ? `- ${t("see_less")}`
                            : `+ ${t("see_more")}`}
                        </a>
                      )}
                    </>
                  )}
                  <div className="actions mt-3">
                    {booking.Configurations[0].Quotes && (
                      <div className="price">
                        {t("price")}: &nbsp;
                        {booking.TxCurrencyCode === "JPY" ? "¥" : ""}
                        {booking.Configurations[0].Quotes &&
                        !isEmpty(totalPrice)
                          ? getPrice(
                              booking.Id,
                              booking.Configurations[0].Quotes[0].TotalPrice
                            )
                          : formatMoney(
                              booking.Configurations[0].Quotes[0].TotalPrice
                            )}
                      </div>
                    )}
                    {booking.Configurations[0].Quotes ? (
                      <button
                        className="px-4 py-2 bg-twprimary text-white rounded"
                        onClick={() => submitBooking(booking)}
                      >
                        {onRequest === "true"
                          ? t("request_to_book")
                          : service?.IndustryCategoryGroups &&
                            service.IndustryCategoryGroups[0] === 1
                          ? t("book_now_activ")
                          : service.IndustryCategoryGroups[0] === 3
                          ? t("book_now_goods")
                          : t("book_now")}
                      </button>
                    ) : (
                      <p style={{ fontSize: "24px" }}>Not Available</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center">
          {service?.IndustryCategoryGroups &&
          service.IndustryCategoryGroups[0] === 1
            ? t("not_available_activ")
            : service.IndustryCategoryGroups[0] === 3
            ? t("not_available_goods")
            : t("not_available")}
        </p>
      )}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("modal_header")}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          dangerouslySetInnerHTML={{ __html: t("modal_desc") }}
        ></Modal.Body>
        <Modal.Footer>
          <button
            className="px-4 py-2 bg-twprimary text-white rounded"
            onClick={goToRequestBook}
          >
            {t("confirm")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ProductItems.propTypes = propTypes;

export default ProductItems;
