import React, { useEffect, useState } from "react";
import axios from "axios";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import moment from "moment";

import {
  bodyRequest,
  distributorQuick,
  distributorRequest,
} from "../../helpers/utils";
import { endpoints } from "../../helpers/endpoints";

import SkeletonProducts from "./components/SkeletonProducts";
import Filter from "./components/Filter";
import Map from "../../components/Maps";
import Items from "./components/Items";
import Breadcrumb from "./components/Breadcrumb";

import "react-loading-skeleton/dist/skeleton.css";
import "./style.scss";
import { Button } from "react-bootstrap";

const Products = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  // eslint-disable-next-line no-unused-vars
  const [changeLanguage, language] = useOutletContext();

  const options = [
    {
      value: "Rate-Ascending",
      label: t("rate_ascending"),
    },
    {
      value: "Rate-Descending",
      label: t("rate_descending"),
    },
    {
      value: "Name-Ascending",
      label: t("name_ascending"),
    },
    {
      value: "Name-Descending",
      label: t("name_descending"),
    },
  ];

  const [stateMap, setStateMap] = useState([]);
  const [services, setServices] = useState([]);
  const [geocodes, setGeocodes] = useState(false);

  const [skeletonShow, setSkeletonShow] = useState("none");
  const [productsShow, setProductsShow] = useState("block");
  const [error, setError] = useState(false);
  const [stateButton, setStateButton] = useState("quick");
  const [selectedProvider, setSelectedProvider] = useState("kagamino");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const [selectedOption, setSelectedOption] = useState("");

  const [category, setCategory] = useState();
  const [date, setDate] = useState("");
  const [priceRange, setPriceRange] = useState();
  const [keyword, setKeyword] = useState();
  const [selectedKeyword, setSelectedKeyword] = useState([]);
  const [token, setToken] = useState();
  const [campaign, setCampaign] = useState();
  const [campaignProps, setCampaignProps] = useState();

  const [breadcrumbName, setBreadcrumbName] = useState("");

  const productsRequest = bodyRequest;
  const langParams = searchParams.get("lang");

  const obxLang = (lang) => {
    if (lang === "jp") {
      return "ja-JP";
    } else if (lang === "cn" || lang === "tw") {
      return "zh-CN";
    } else {
      return "en-US";
    }
  };

  useEffect(() => {
    searchParams.delete("pages");
    setSearchParams(searchParams);
    setError(false);

    setPage(1);
    setToken(null);
    productsRequest.request.Paging.PageNumber = 1;

    productsRequest.request.Availability = {
      MergeMethod: 1,
      Specific: {
        Date: moment().format("YYYY-MM-DD"),
        Duration: 1,
      },
    };
    productsRequest.request.Filter = {
      TagCritera: {},
      Type: "Service",

      Bookability: {
        IsBookable: true,
        GuestsCapability: 0,
        NightsCapability: null,
        IncludeOnRequest: true,
        BlockUnavailableResults: false,
        RateRange: {
          Min: null,
          Max: null,
        },
      },
    };

    const category = searchParams.get("category");
    const min = searchParams.get("min");
    const max = searchParams.get("max");
    const keyword = searchParams.get("keyword");
    const date = searchParams.get("date");
    const sort = searchParams.get("sort");
    const type = searchParams.get("type");
    const provider = searchParams.get("provider");
    const campaignParams = searchParams.get("campaign");

    let filterValues = {};

    if (category) {
      productsRequest.request.Filter.TagCriteria = {
        IndustryCategoryGroups: [category],
      };
      setCategory(parseInt(category));
    }

    if (campaignParams) {
      setCampaign(campaignParams);
      productsRequest.request.Campaign.AdCampaignCode = campaignParams;
      loadCampaign(campaignParams);
    }

    if (provider) {
      setSelectedProvider(provider);
    }

    if (min || max) {
      productsRequest.request.Filter.Bookability.RateRange = {
        Min: min,
        Max: max,
      };
      setPriceRange(`${min}-${max}`);
    }

    if (date) {
      productsRequest.request.Availability.Specific.StartDate =
        moment().format("YYYY-MM-DD");
      setDate(new Date(date));
      filterValues = {
        date: new Date(date),
      };
    }

    if (keyword) {
      productsRequest.request.Filter.Names = [`%${keyword}%`];
      setKeyword(keyword);
    }

    if (!isEmpty(selectedKeyword)) {
      productsRequest.request.Filter.Names = [`%${selectedKeyword}%`];
    }

    if (sort) {
      setSelectedOption(sort);
      productsRequest.request.Sorting = [
        {
          By: `${sort.split("-")[0]}`,
          Direction: `${sort.split("-")[1]}`,
        },
      ];
    } else {
      productsRequest.request.Sorting = [
        {
          By: "Random",
        },
      ];
    }

    delete productsRequest.request.Filter.Ids;

    productsRequest.request.Language = obxLang(language);
    pageNames();
    getData({
      type: type || "quick",
      filter: true,
      filterValues,
      provider: provider || "kagamino",
    });
  }, []);

  useEffect(() => {
    productsRequest.request.Language = obxLang(language);
    let filterValues = {};
    const date = searchParams.get("date");
    if (date) {
      setDate(new Date(date));
      filterValues = {
        date: new Date(date),
      };
    }
    if (langParams === language) {
      pageNames();
      getData({ filterValues, type: stateButton });
      window.scrollTo(0, 0);
    }
  }, [language]);

  const loadCampaign = (param) => {
    axios
      .post(endpoints.campaign, {
        Code: campaign || param,
        shortname: distributorQuick,
      })
      .then((res) => {
        setCampaignProps(res.data);
        console.log(campaignProps);
      });
  };

  const resetPage = () => {
    setPage(1);
    searchParams.delete("pages");
    setToken(null);
  };

  useEffect(() => {
    stateMap &&
      stateMap.map((item) => {
        if (item.HasGeocodes) {
          setGeocodes(true);
        }
      });
  }, [stateMap]);

  // Dispatch data
  const dispatchData = (params) => {
    productsRequest.request.ShortName =
      params?.type === "request" ? distributorRequest : distributorQuick;
    productsRequest.request.Paging.PageNumber = params?.page || 1;
    productsRequest.request.Paging.PageSize = 12;

    if (params?.type === "request") {
      productsRequest.request.Filter.Bookability = {
        IsBookable: true,
        GuestsCapability: 0,
        NightsCapability: null,
        IncludeOnRequest: true,
        BlockUnavailableResults: false,
        RateRange: {
          Min: null,
          Max: null,
        },
      };
    }
    setError(false);
    setServices([]);
    axios
      .post(endpoints.search, productsRequest)
      .then((response) => {
        let datas = [];
        const parentsData = response?.data?.Parents;
        if (params?.page && params?.page > 1) {
          if (params?.values?.date) {
            response.data.Entities.forEach((el) => {
              el?.Children[0]?.Availability?.Calendar?.DailyRates?.forEach(
                (rate) => {
                  if (
                    rate?.Date ===
                      moment(params?.values?.date).format("YYYY-MM-DD") &&
                    rate?.IsAvailable
                  ) {
                    setServices((services) => [...services, el]);
                    datas = [...datas, el];
                  }
                }
              );
            });
          }
          setServices((data) => [...data, ...response.data.Entities]);
          datas = [...datas, ...response.data.Entities];
        } else {
          if (params?.values?.date) {
            response.data.Entities.forEach((el) => {
              el?.Children[0]?.Availability?.Calendar?.DailyRates?.forEach(
                (rate) => {
                  if (
                    rate?.Date ===
                      moment(params?.values?.date).format("YYYY-MM-DD") &&
                    rate?.IsAvailable
                  ) {
                    setServices((services) => [...services, el]);
                    datas = [...datas, el];
                  }
                }
              );
            });
          } else {
            setServices(response.data.Entities);
            datas = response.data.Entities;
          }
          setTotalPage(response.data.Paging.NumberOfPages);
          setToken(response.data.Paging.Token);
          setPageCount(response?.data?.Paging?.NumberOfResults);
        }

        const sort = searchParams.get("sort");
        if (sort && sort === "Rate-Ascending") {
          datas.sort((a, b) => {
            return (
              a.Availability?.Calendar?.LowestRate -
              b.Availability?.Calendar?.LowestRate
            );
          });
        } else if (sort && sort === "Rate-Descending") {
          datas.sort((a, b) => {
            return (
              b.Availability?.Calendar?.LowestRate -
              a.Availability?.Calendar?.LowestRate
            );
          });
        }

        if (params?.type !== "request") {
          const setProvider = params?.provider || selectedProvider;
          const parentsArray = Object.values(parentsData);
          const selectedParent = parentsArray.filter(
            (parent) => parent.Code === setProvider
          );
          if (isEmpty(selectedParent)) {
            setServices([]);
            setPageCount(0);
          } else {
            datas = datas.filter(
              (item) => item.ParentId === selectedParent[0].Id
            );
            setServices(datas);
            setPageCount(response?.data?.Paging?.NumberOfResults);
          }
        }
        setProductsShow("block");
        setSkeletonShow("none");
        window.scrollTo(0, 0);
      })
      .catch(() => {
        setError(true);
        setProductsShow("block");
        setSkeletonShow("none");
      });
  };

  const updateStateMap = (data) => {
    setStateMap((ss) => [...ss, ...data]);
  };

  const dispatchMap = (type) => {
    productsRequest.request.Paging = {};
    productsRequest.request.ShortName =
      type === "request" ? distributorRequest : distributorQuick;

    axios.post(endpoints.search, productsRequest).then((response) => {
      updateStateMap(response.data.Entities);
    });
  };

  const getData = (payload) => {
    setSkeletonShow("block");
    productsRequest.request.Output.Children = {
      Output: {
        CommonContent: {
          All: true,
        },
        Features: true,
        Rating: true,
        Reviews: {
          IncludeFullDescription: true,
          IncludeShortReview: true,
          MaxReturnCount: 10,
          MaxReturnCountSpecified: true,
        },
        Availability: {
          StartDate: payload?.filterValues?.date
            ? moment(payload?.filterValues?.date).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          NumberOfDays: 10,
          MergeMethod: 2,
          FlagCampaign: true,
        },
      },
      Filter: {
        Ids: null,
        Type: 4,
      },
    };
    if (payload?.page && payload?.page > 1) {
      productsRequest.request.Paging.PageNumber = payload?.page;
      searchParams.get("page", page);
      productsRequest.request.Paging.Token = token;
      delete productsRequest.request.Filter;
      delete productsRequest.request.Campaign;
      delete productsRequest.request.Availability;

      dispatchData({
        page: payload?.page,
        type: payload?.type,
        provider: payload?.provider,
      });
    } else {
      setProductsShow("none");
      productsRequest.request.Paging.PageNumber = 1;
      if (!payload?.filter) {
        productsRequest.request.Availability = {
          MergeMethod: 1,
          Specific: {
            Date: moment().format("YYYY-MM-DD"),
            Duration: 1,
          },
        };
        productsRequest.request.Filter = {
          TagCritera: {},
          Type: "Service",
          Bookability: {
            IsBookable: true,
            GuestsCapability: 0,
            NightsCapability: null,
            IncludeOnRequest: true,
            BlockUnavailableResults: true,
            RateRange: {
              Min: null,
              Max: null,
            },
          },
        };

        if (payload?.type === "request") {
          productsRequest.request.Filter.Bookability = {
            IsBookable: true,
            GuestsCapability: 0,
            NightsCapability: null,
            IncludeOnRequest: true,
            BlockUnavailableResults: true,
            RateRange: {
              Min: null,
              Max: null,
            },
          };
        }
      } else if (payload?.filterValues?.date) {
        productsRequest.request.Output.Availability = {
          StartDate: moment(payload?.filterValues?.date).format("YYYY-MM-DD"),
          NumberOfDays: 10,
        };
      }

      dispatchData({
        page: null,
        type: payload?.type,
        values: payload?.filterValues,
        provider: payload?.provider,
      });
    }

    dispatchMap("quick");
    dispatchMap("request");
  };

  // Filter
  const filterData = (values) => {
    resetPage();
    productsRequest.request.Filter = {
      Type: "Service",
      TagCriteria: {
        IndustryCategoryGroups: [0, 1, 2, 3],
      },
      Bookability: {
        IsBookable: true,
        GuestsCapability: 0,
        NightsCapability: null,
        IncludeOnRequest: true,
        BlockUnavailableResults: false,
        RateRange: {
          Min: null,
          Max: null,
        },
      },
    };

    if (values.minRange) {
      if (values.minRange === "0") {
        productsRequest.request.Filter.Bookability.RateRange = {};
        searchParams.delete("min");
        searchParams.delete("max");
      } else {
        productsRequest.request.Filter.Bookability.RateRange = {
          Min: values.minRange,
          Max: values.maxRange,
        };
        searchParams.set("min", values.minRange);
        searchParams.set("max", values.maxRange);
      }
    }
    if (values.date) {
      productsRequest.request.Availability.Specific.Date = moment(
        new Date(values.date)
      ).format("YYYY-MM-DD");
      productsRequest.request.Availability.Specific.Duration = 1;
      if (moment(values.date).format("LL") !== moment().format("LL")) {
        searchParams.set(
          "date",
          moment(new Date(values.date)).format("YYYY-MM-DD")
        );
      } else {
        searchParams.delete("date");
      }
    } else {
      searchParams.delete("date");
    }

    if (!values.category) {
      searchParams.delete("category");
    } else {
      productsRequest.request.Filter.TagCriteria = {
        IndustryCategoryGroups: [values.category],
      };
      searchParams.set("category", values.category);
    }
    const dataKeyword = selectedKeyword.map((item) => `%${item}%`);

    if (values.keyword) {
      dataKeyword.push(`%${values.keyword}%`);
      productsRequest.request.Filter.Names = dataKeyword;
      searchParams.set("keyword", values.keyword);
    } else {
      if (!isEmpty(values.selectedKeyword)) {
        delete productsRequest.request.Filter.Names;
      }
      searchParams.delete("keyword");
    }

    if (page > 1) {
      setPage(1);
      setTotalPage(1);
    }

    setSearchParams(searchParams);
    pageNames();

    getData({ filter: true, filterValues: values });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };

  const changeToQuick = () => {
    let filterValues = {};
    const date = searchParams.get("date");
    if (date) {
      setDate(new Date(date));
      filterValues = {
        date: new Date(date),
      };
    }
    getData({ page: 1, type: "quick", filterValues });
    setStateButton("quick");
    searchParams.set("type", "quick");
  };

  const changeToRequest = () => {
    setStateButton("request");
    searchParams.set("type", "request");
    let filterValues = {};
    const date = searchParams.get("date");
    if (date) {
      setDate(new Date(date));
      filterValues = {
        date: new Date(date),
      };
    }

    getData({ page: 1, type: "request", filterValues });
  };

  const changeToMap = () => {
    setStateButton("map");
  };

  // Sort
  const onSort = (value) => {
    resetPage();
    setSelectedOption(value);
    productsRequest.request.Sorting = [
      {
        By: `${value.split("-")[0]}`,
        Direction: `${value.split("-")[1]}`,
        PositionOfNull: "AlwaysOnBottom",
      },
    ];
    searchParams.set("sort", value);
    setSearchParams(searchParams);

    let filterValues = {};
    const date = searchParams.get("date");
    if (date) {
      setDate(new Date(date));
      filterValues = {
        date: new Date(date),
      };
    }

    getData({ filterValues, type: stateButton });
  };

  // Load more data
  const loadMore = (selectedPage) => {
    const paging = selectedPage;
    searchParams.set("pages", paging);
    searchParams.delete("pages_request");
    setSearchParams(searchParams);

    setPage(paging);
    getData({ page: paging, type: stateButton, loadMore: true });
  };

  const pageNames = () => {
    let breadcrumb = "";
    const category = searchParams.get("category");
    switch (category) {
      case "0":
        breadcrumb = t("accommodation");
        break;
      case "1":
        breadcrumb = t("activity");
        break;
      case "2":
        breadcrumb = t("restaurant");
        break;
      case "3":
        breadcrumb = t("shopping");
        break;
      default:
        breadcrumb = t("search");
        break;
    }

    setBreadcrumbName(breadcrumb);
  };

  const changeProvider = (provider) => {
    setSelectedProvider(provider);
    searchParams.set("provider", provider);
    setSearchParams(searchParams);
    getData({ type: stateButton, provider });
  };

  return (
    <div className="products pb-0 px-4">
      <div className="productsWrapper" style={{ display: productsShow }}>
        <Breadcrumb category={category} breadcrumbName={breadcrumbName} />
        <div className="text-3xl lg:text-4xl font-bold container lg:text-center">
          {t("experience_reservation")}
        </div>
        <Filter
          lang={language}
          filter={filterData}
          date={date}
          category={category}
          priceRange={priceRange}
          keyword={keyword}
          setKeyword={setKeyword}
          setDate={setDate}
          setCategory={setCategory}
          setPriceRange={setPriceRange}
          selectedKeyword={selectedKeyword}
          setSelectedKeyword={setSelectedKeyword}
        />

        <div className="pt-8 pb-32 container">
          <div className="mb-6 flex gap-4 overflow-auto whitespace-nowrap">
            <button
              type="button"
              className={`pb-2 px-4 border-b-2 border-gray-400 text-lg text-gray-400 ${
                !selectedProvider || selectedProvider === "kagamino"
                  ? "!border-twprimary !text-twprimary font-bold"
                  : ""
              }`}
              onClick={() => changeProvider("kagamino")}
            >
              {t("kagamino")}
            </button>
            <button
              type="button"
              className={`pb-2 px-4 border-b-2 border-gray-400 text-lg text-gray-400 ${
                selectedProvider === "TestProviderHotel"
                  ? "!border-twprimary !text-twprimary font-bold"
                  : ""
              }`}
              onClick={() => changeProvider("TestProviderHotel")}
            >
              {t("other_providers")}
            </button>
            {/* <a
              href="?campaign=testcampaign"
              className={`pb-2 px-4 border-b-2 border-gray-400 text-lg text-gray-400 ${
                campaign === "testcampaign"
                  ? "!border-twprimary !text-twprimary font-bold"
                  : ""
              }`}
            >
              {t("campaign")}
            </a> */}
          </div>
          <div className=" flex flex-col lg:flex-row gap-2 lg:justify-between lg:items-center mb-8 options">
            <div className="searchCount">
              <span>{pageCount || 0}</span>
              <span className="count">{t("product_items_jp")}</span>{" "}
              {t("product_items")}
            </div>
            <div className="flex lg:justify-end justify-start items-center flex-wrap lg:!flex-nowrap">
              <select
                className="w-full flex-shrink-0 lg:max-w-[160px] form-select"
                onChange={(e) => onSort(e.target.value)}
              >
                {options.map((o, i) => (
                  <option
                    key={i}
                    selected={selectedOption === o.value}
                    value={o.value}
                  >
                    {o.label}
                  </option>
                ))}
              </select>
              <select
                className="w-full flex-shrink-0 lg:max-w-[190px] form-select"
                onChange={(e) => changeLanguage(e.target.value)}
                value={langParams ? langParams : language}
              >
                <option value="jp">日本語 (Japanese)</option>
                <option value="en">英語 (English)</option>
                <option value="cn">中文简体 (Chinese)</option>
              </select>
              <div className="flex items-center">
                <Button
                  variant={stateButton === "quick" ? "secondary" : "white"}
                  onClick={() => changeToQuick()}
                  className="whitespace-nowrap"
                >
                  {t("quick_booking")}
                </Button>
                <Button
                  variant={stateButton === "request" ? "secondary" : "white"}
                  onClick={() => changeToRequest()}
                  className="whitespace-nowrap"
                >
                  {t("request_book")}
                </Button>
                <Button
                  variant={stateButton === "map" ? "secondary" : "white"}
                  onClick={() => changeToMap()}
                  className="whitespace-nowrap"
                >
                  {t("map")}
                </Button>
              </div>
            </div>
          </div>
          <div className="">
            <div
              className="productItems"
              style={{ display: stateButton !== "map" ? "block" : "none" }}
            >
              <Items
                services={services}
                loadMore={loadMore}
                totalPage={totalPage}
                currentPage={page}
                category={category}
                stateButton={stateButton}
                error={error}
                pageCount={pageCount}
                getData={getData}
              />
            </div>
            <div
              className="productsMap"
              style={{ display: stateButton === "map" ? "block" : "none" }}
            >
              {geocodes && stateMap.length > 0 && (
                <Map positions={stateMap} zoom={9} />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="skeletonWrapper" style={{ display: skeletonShow }}>
          <SkeletonProducts currentPage={page} category={category} />
        </div>
      </div>
    </div>
  );
};

export default Products;
