import { useTranslation } from "react-i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { formatMoney } from "../../helpers/formatters";
import SkeletonItems from "./SkeletonItems";

import "./style.scss";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

const Invoice = () => {
  const [bookingDetails, setBookingDetails] = useState(null);
  const [customerDetails, setCustomerDetails] = useState({});
  const [productDetails, setProductDetails] = useState({});
  const [error, setError] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();

  const handleClose = () => {
    setShowPrivacy(false);
  };

  useEffect(() => {
    const session_id = searchParams.get("session_id");
    axios
      .get(`https://be.kagamino.tours/api/session/get?session_id=${session_id}`)
      .then((response) => {
        setBookingDetails(response?.data?.session);
        setCustomerDetails(response?.data?.session?.customer_details);
        setProductDetails(response?.data?.session?.metadata);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  }, []);

  return (
    <>
      <div className="container py-14">
        <a
          href="https://kagamino.tours"
          className="px-4 py-2 rounded bg-twsecondary inline-block text-white fw-bold mb-4"
        >
          {t("back_to_booking")}
        </a>
        {!error ? (
          bookingDetails ? (
            <div className="wrapper">
              <div className="header text-lg">
                {t("booking_req_detail_wait")}
              </div>
              <div className="body">
                <div className="mb-3"> {t("booking_req_instructions")}</div>
                <div className="grey p-3">
                  <div className="row">
                    <div className="col-3">
                      <b>{t("booking_date")}</b>
                    </div>
                    <div className="col-9 border-start">
                      {moment(bookingDetails.requested_at).format("LLL")}
                    </div>
                  </div>
                  <div className="row border-t border-gray-400">
                    <div className="col-3">
                      <b>{t("booking_reference")}</b>
                    </div>
                    <div className="col-9 border-start">
                      {bookingDetails.status}
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-6">
                      <div className="border-b border-gray-400">
                        <b>{t("booked_by")}:</b>
                      </div>
                      <div className="flex flex-col">
                        <span>
                          <b>{t("name")}:</b>&nbsp;
                          {customerDetails.name}
                        </span>
                        <span>
                          <b>{t("address")}:</b>&nbsp;
                          {customerDetails.address}
                        </span>
                        <span>
                          <b>{t("phone")}:</b>&nbsp;
                          {customerDetails.phone}
                        </span>
                        <span>
                          <b>{t("mobile")}:</b>&nbsp;
                          {customerDetails.mobile}
                        </span>
                        <span>
                          <b>{t("email")}:</b>&nbsp;
                          {customerDetails.email}
                        </span>
                      </div>
                    </div>
                    <div className="col-6 border-start">
                      <div className="border-b border-gray-400">
                        <b>{t("supplier_information")}:</b>
                      </div>
                      <div className="flex flex-col">
                        <span>
                          <b>{t("name")}:</b>&nbsp;
                          {productDetails.ProductDetails_SupplierName}
                        </span>
                        <span>
                          <b>{t("address")}:</b>&nbsp;
                          {productDetails.ProductDetails_SupplierAddress}
                        </span>
                        <span>
                          <b>{t("phone")}:</b>&nbsp;
                          {productDetails.ProductDetails_SupplierPhone}
                        </span>
                        <span>
                          <b>{t("website")}:</b>&nbsp;
                          {productDetails.ProductDetails_SupplierWebsite}
                        </span>
                        <span>
                          <b>{t("email")}:</b>&nbsp;
                          {productDetails.ProductDetails_SupplierEmail}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-12 fw-bold">{t("request_to_book")}</div>
                  </div>
                  <div className="row align-items-start">
                    <div className="col-3">
                      <div className="border-b border-gray-400 border-t">
                        <b>{t("product")}</b>
                      </div>
                      <div className="d-flex flex-col">
                        {productDetails.ProductDetails_ProductName}
                      </div>
                    </div>
                    <div className="col-3 border-start">
                      <div className="border-b border-gray-400 border-t">
                        <b>{t("options")}</b>
                      </div>
                      <div className="d-flex flex-col">
                        {productDetails.ProductDetails_Adults}
                        &nbsp;
                        {t("adult")}
                      </div>
                    </div>
                    <div className="col-3 border-start border-end">
                      <div className="border-b border-gray-400 border-t">
                        <b>{t("date")}</b>
                      </div>
                      <div className="d-flex flex-col">
                        <div className="mb-3">
                          <h6 className="mb-0">
                            <b>{t("check_in")}</b>
                          </h6>
                          <p className="mb-0">
                            {moment(
                              productDetails.ProductDetails_CommencementDate
                            ).format("LLL")}
                          </p>
                        </div>
                        <div className="mb-3">
                          <h6 className="mb-0">
                            <b>{t("check_out")}</b>
                          </h6>
                          <p className="mb-0">
                            {moment(
                              productDetails.ProductDetails_ConcludeDate
                            ).format("LLL")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="border-b border-gray-400 border-t">
                        <b>Totals</b>
                      </div>
                      <div className="flex flex-col">
                        <h6 className="text-end">
                          {productDetails.ProductDetails_CurrentCurrency}
                          {productDetails.ProductDetails_Price &&
                            formatMoney(productDetails.ProductDetails_Price)}
                        </h6>
                      </div>
                    </div>
                    {bookingDetails.product_extras &&
                      JSON.parse(bookingDetails.product_extras).length > 0 &&
                      JSON.parse(bookingDetails.product_extras).map((extra) => {
                        return (
                          <>
                            <div className="col-3 border-t border-gray-400">
                              {extra.Name}
                            </div>
                            <div className="col-3 border-t border-gray-400" />
                            <div className="col-3 border-t border-gray-400" />
                            <div className="col-3 border-t border-gray-400 border-l">
                              <h6 className="text-end">
                                {productDetails.ProductDetails_CurrentCurrency}
                                {formatMoney(extra.TotalCost)}
                              </h6>
                            </div>
                          </>
                        );
                      })}
                    <div className="col-9 border-end border-t border-gray-400">
                      <h6 className="text-end">{t("total_gst")}</h6>
                    </div>
                    <div className="col-3 border-t border-gray-400">
                      <h6 className="text-end">
                        {bookingDetails.TotalPrice ? (
                          `${
                            productDetails.ProductDetails_CurrentCurrency
                          }${formatMoney(bookingDetails.TotalPrice)}`
                        ) : (
                          <p>-</p>
                        )}
                      </h6>
                    </div>
                    {/* <div className="col-9 border-end border-t">
                      <h6 className="text-end">{t("amount_paid")}</h6>
                    </div>
                    <div className="col-3 border-t">
                      <h6 className="text-end">
                        {
                          
                            productDetails.ProductDetails_CurrentCurrency
                        }
                      </h6>
                    </div> */}
                    <div className="col-9 border-end border-t border-b border-gray-400">
                      <h6 className="text-end">{t("amount_owing")}</h6>
                    </div>
                    <div className="col-3 border-t border-b border-gray-400">
                      <h6 className="text-end">
                        {bookingDetails.TotalPrice ? (
                          `${
                            productDetails.ProductDetails_CurrentCurrency
                          }${formatMoney(bookingDetails.TotalPrice)}`
                        ) : (
                          <p>-</p>
                        )}
                      </h6>
                    </div>
                  </div>
                  <b>{t("special_requests")}</b>
                  <br />
                  <p className="mb-0">
                    {customerDetails.specialRequest ? (
                      customerDetails.specialRequest
                    ) : (
                      <span>-</span>
                    )}
                  </p>
                </div>

                <div className="p-3">
                  <div className="border-b border-gray-400 mt-3">
                    <h6 className="font-bold">Conditions of Use</h6>
                    <p>condition</p>
                  </div>
                  <div className="border-b border-gray-400 mt-3">
                    <h6 className="font-bold">Booking Terms</h6>
                    <p>condition</p>
                  </div>
                  <div className="border-b border-gray-400 mt-3">
                    <h6 className="font-bold">TXJ Privacy Policy</h6>
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPrivacy(true)}
                    >
                      Click to view more
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <SkeletonItems />
          )
        ) : (
          <h5 className="text-center">{t("not_found_page")}</h5>
        )}
      </div>

      <Modal show={showPrivacy} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>TXJ Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>Privacy Text</Modal.Body>
        <Modal.Footer>
          <button
            className="px-4 py-2 text-white rounded bg-twprimary"
            onClick={handleClose}
          >
            {t("close")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Invoice;
