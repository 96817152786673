import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import DefaultImg from "../../../../assets/images/no_image.png";
import { formatMoney } from "../../../../helpers/formatters";
import { useNavigate } from "react-router-dom";

import "./style.scss";

const propTypes = {
  services: PropTypes.array,
  loadMore: PropTypes.func,
  totalPage: PropTypes.number,
  currentPage: PropTypes.number,
  category: PropTypes.number,
  stateButton: PropTypes.string,
  error: PropTypes.bool,
  getData: PropTypes.func,
  pageCount: PropTypes.number,
};

const Items = ({
  services,
  loadMore,
  totalPage,
  currentPage,
  stateButton,
  error,
  getData,
  pageCount,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [lang, setLang] = useState("en");

  const goToDetail = (id) => {
    navigate(
      `/product?id=${id}&lang=${lang}${
        stateButton === "request" ? "&onRequest=true" : ""
      }`
    );
  };
  const pageNumberArray = Array.from(
    { length: totalPage },
    (_, index) => index + 1
  );

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center gap-6">
        <h4 className="text-xl">Something went wrong</h4>
        <button
          className="py-2 btn btn-secondary px-4"
          onClick={() => getData()}
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <>
      <section>
        {services && services.length > 0 ? (
          <div className="grid lg:grid-cols-4 gap-10 lg:gap-6 gap-x-8 mb-10 ">
            {services.map((service) => {
              return (
                <div
                  className="cursor-pointer border-b-2 border-black pb-2"
                  key={service.Id}
                  onClick={() => goToDetail(service.Id)}
                >
                  <div
                    className="image"
                    onClick={(e) => {
                      e.preventDefault();
                      goToDetail(service.Id);
                    }}
                  >
                    <img
                      src={
                        service.Images !== null
                          ? service.Images[0].Url
                          : DefaultImg
                      }
                      className="h-[220px] lg:h-[180px] w-full object-cover"
                      alt={service.Name}
                    />
                  </div>

                  <div className="pt-3">
                    <div
                      className="font-bold text-lg mb-2 line-clamp-2"
                      onClick={(e) => {
                        e.preventDefault();
                        goToDetail(service.Id);
                      }}
                    >
                      {service.Name}
                    </div>
                    {service.Availability.Calendar.LowestRate && (
                      <div className="price !mb-3 font-bold">
                        {lang === "jp"
                          ? `¥${formatMoney(
                              service.Availability.Calendar.LowestRate
                            )} ~`
                          : `${t("from")} ¥${formatMoney(
                              service.Availability.Calendar.LowestRate
                            )}`}
                      </div>
                    )}
                    <div
                      className="desc line-clamp-3 !mb-3 font-bold text-xs"
                      dangerouslySetInnerHTML={{
                        __html: service.ShortDescription
                          ? service.ShortDescription
                          : service.LongDescription,
                      }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <h3 className="text-center text-2xl mb-4">{t("not_found")}</h3>
        )}
      </section>
      <div className="flex justify-center items-center mb-3 gap-1 flex-wrap">
        {pageCount > 0 &&
          pageNumberArray.map((element) => {
            return (
              <button
                className={`w-8 h-8 flex justify-center items-center bg-transparent border-twprimary border text-base text-twprimary font-bold !rounded-none ${
                  currentPage === element ? "!bg-twprimary !text-white" : ""
                }`}
                onClick={() => loadMore(element)}
                key={element}
              >
                {element}
              </button>
            );
          })}
        {/* {totalPage > 1 && totalPage > currentPage && state === "quick" && (
          <button
            className="py-2 btn btn-secondary py-4"
            onClick={() => loadMore()}
          >
            {t("load_more")}
          </button>
        )} */}
      </div>
    </>
  );
};

Items.propTypes = propTypes;

export default Items;
