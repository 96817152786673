import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

Breadcrumb.propTypes = {
  breadcrumbName: PropTypes.string,
  category: PropTypes.any,
};

function Breadcrumb({ category, breadcrumbName }) {
  const { t } = useTranslation();
  return (
    <div className="container">
      <p id="breadcrumbs">
        <span>{t("home")}</span>
        {category || category === 0 ? (
          <>
            <span>
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
            <a href={`?category=${category}`}>{breadcrumbName}</a>
          </>
        ) : null}
      </p>
    </div>
  );
}

export default Breadcrumb;
