import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Calendar from "react-calendar";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import "react-calendar/dist/Calendar.css";
import "./style.scss";

const propTypes = {
  filter: PropTypes.func,
  setDate: PropTypes.func,
  category: PropTypes.number,
  date: PropTypes.string,
  keyword: PropTypes.string,
  setKeyword: PropTypes.func,
  setSelectedKeyword: PropTypes.func,
  selectedKeyword: PropTypes.array,
};

const Filter = ({ filter, category, keyword, setKeyword, setDate, date }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryParams = searchParams.get("type");

  const [showCalendar, setShowCalendar] = useState(false);

  const onChange = (date) => {
    setDate(date);
    setShowCalendar(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const dateData = categoryParams === "3" ? false : formData.get("date");
    const data = {
      date: dateData ? new Date(formData.get("date")) : "",
      category:
        formData.get("category") === "none" ? null : formData.get("category"),
      keyword: formData.get("keyword"),
    };
    setShowCalendar(false);
    filter(data);
  };

  const resetFilter = () => {
    setDate("");
    searchParams.delete("category");
    searchParams.delete("date");
    searchParams.delete("keyword");
    setSearchParams(searchParams);
    const data = {};
    filter(data);
  };

  const minDate = () => {
    const someDate = new Date();
    const result = someDate.setDate(someDate.getDate() + 0);
    return new Date(result);
  };
  return (
    <div className="filterWrapper">
      <div className="container max-w-[800px] bg-white border-twbrown border-2">
        <div className="font-bold mb-2 text-center bg-twbrown text-white p-2 text-2xl">
          {t("search_filter")}
        </div>
        <form onSubmit={handleSubmit} className="p-6">
          <div className="relative grid gap-3 font-bold">
            <div className="flex w-full gap-3 lg:gap-6 lg:items-center lg:px-12 flex-col lg:flex-row">
              <div className="whitespace-nowrap w-1/4">{t("category")}</div>
              <Form.Select name="category" className="w-3/4">
                <option value="none">{t("all_categories")}</option>
                <option value="0">{t("accommodation")}</option>
                <option value="1">{t("activity")}</option>
                <option value="2">{t("restaurant")}</option>
                <option value="3">{t("shopping")}</option>
              </Form.Select>
            </div>
            {category !== 3 && (
              <div className="flex w-full gap-3 lg:gap-6 lg:items-center lg:px-12 flex-col lg:flex-row">
                <div className="whitespace-nowrap w-1/4">
                  {t("date_placeholder")}
                </div>
                <div className="w-3/4">
                  <Form.Control
                    type="input"
                    name="date"
                    placeholder={t("date_placeholder")}
                    readOnly
                    value={date && moment(date).format("YYYY-MM-DD")}
                    onClick={() => {
                      setShowCalendar(!showCalendar);
                    }}
                    className="w-full"
                  />
                  <Calendar
                    minDate={minDate()}
                    onChange={(date) => onChange(date)}
                    value={date}
                    className={!showCalendar ? "hide" : ""}
                  />
                </div>
              </div>
            )}
            <div className="flex w-full gap-3 lg:gap-6 lg:items-center lg:px-12 flex-col lg:flex-row">
              <div className="whitespace-nowrap w-1/4">{t("keyword_plc")}</div>
              <div className="w-3/4">
                <Form.Control
                  type="text"
                  name="keyword"
                  onClick={() => {
                    setShowCalendar(false);
                  }}
                  className="keywordType w-full"
                  onChange={(e) => setKeyword(e.target.value)}
                  placeholder={t("keyword_plc")}
                  value={keyword}
                />
              </div>
            </div>
            <button
              type="button"
              className="underline text-xs mt-1 lg:px-12 text-left"
              onClick={resetFilter}
            >
              {t("reset_filter")}
            </button>
            <div className="w-full lg:px-12 h-[44px]">
              <button
                className="searchButton !bg-black h-full !w-[240px] py-6 !mx-auto gap-2 flex items-center justify-center"
                type="submit"
              >
                <div>{t("search_filter")}</div>
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

Filter.propTypes = propTypes;

export default Filter;
